<template>
  <div>
    <d-body-top-bar :title="$t('views.credits.credits-label')">
      <div slot="tree">
        <label class="bg-doinsport-primary ml-1"> {{ $t('general.actions.list') }}</label>
      </div>
      <div slot="icons">
      </div>
    </d-body-top-bar>
    <credit-list/>
  </div>
</template>
<script>
export default {
  components: {
    CreditList: () => import('@/views/subscriptions/credit-list/Index')
  },
};
</script>
